<!--
 * @Author: hwu
 * @Date: 2020-08-28 12:16:31
 * @Description: 菜谱页面 -> 主页面
 * @LastEditTime: 2024-04-10 16:13:54
-->
<template>
  <div class="pad-container">
    <div class="app-container pad-container_left">
      <menu-content :with-footer="false" />
    </div>
    <div class="pad-container_divider"></div>
    <div class="app-container pad-container_right">
      <menu-cart-detail-content />
      <!-- 购物车底部栏 -->
      <menu-cart-footer />
    </div>
  </div>
</template>
<script>
import MenuContent from '../MenuContent'
import MenuCartFooter from '../MenuCartFooter'
import MenuCartDetailContent from '../MenuCartDetailContent'
export default {
  components: {
    MenuContent,
    MenuCartFooter,
    MenuCartDetailContent
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>
